<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div class="col app-calendar-sidebar flex-grow-0 d-flex flex-column">
        <b-form
          class="mt-2"
          style="width: 100%"
          @submit.prevent="getDate"
          ref="form">
        
          <b-col md="12">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2">
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addScheduleForm.category_id"
                label="type"
                :options="categoriesList"
                :reduce="(val) => val.id"
                @input="getCoursesByCategory(addScheduleForm.category_id)" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Select Diploma"
              label-for="blog-edit-course"
              class="mb-2">
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addScheduleForm.course"
                label="name"
                :options="coursesList"
               >
               <template #no-options="{ search, searching, loading }" v-if="!getCourse">
                Select Category First ...
               </template>
               <template #no-options="{ search, searching, loading }" v-else>
                Loading ...
               </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Select City" label-for="blog-edit-course">
              <div v-for="(city, i) in citiesList" :key="city.id">
                <b-form-checkbox
                  :id="city.id.toString()"
                  :value="city"
                  v-model="addScheduleForm.city"
                  class="mb-2"
                  @change="getDate">
                  {{ city.name }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-10">
            <b-button variant="primary" class="mr-1" @click="getChanges">
              List Changes
            </b-button>
          </b-col>
        </b-form>
      </div>

      <!-- Calendar -->

      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
            v-if="renderComponent"
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar" />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false" />
    </div>

    <b-row>
      <list-changes @remove-change="emptyChange" ref="table" />
    </b-row>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { ref, reactive, computed } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { onUnmounted ,nextTick} from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
import ListChanges from "./ListChanges.vue";
import Vue from "vue";
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    ListChanges,
    CalendarEventHandler,
    BRow,
    vSelect,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BFormCheckbox,
    BCol,
  },
  setup() {
    const addScheduleForm = reactive({
      category_id: 0,
      city: [],
      course: 0,
    });
    const table = ref();
    const coursesList = ref([]);
    const getDate = () => {
      store.commit("schedule/SET_SELECTED_EVENTS", {
        long_course_id: addScheduleForm.course,
        city_id: addScheduleForm.city,

        category_id: addScheduleForm.category_id,
        online: 0,
      });
    };
    
const renderComponent = ref(true);
    const forceRerender = async () => {
  // Remove MyComponent from the DOM
  renderComponent.value = false;

	// Wait for the change to get flushed to the DOM
	await nextTick();

	// Add the component back in
  renderComponent.value = true;
};
    const showChange = ref(false);
    const getCourse=ref(false)
    const categoriesList = ref([]);
    const citiesList = ref([]);

    store.dispatch("cities/cititesList").then((response) => {
   
      citiesList.value = response.data;
    });
    const emptyChange = () => {
      // window.location.reload()
      // refCalendar.value.render()
      forceRerender()
     //   console.log("refCalendar.value",refCalendar.value)
      const today = new Date();
 
      DeltedEventsList.value=[]
      addScheduleForm.course=''
      addScheduleForm.category_id=''
      addScheduleForm.city=[]
      store.state.schedule.selectedCalendars=[]
      // console.log(refCalendar.value.removeAllEvents())
      // refCalendar.value.removeAllEvents();
      removeAllEvents()
      calendarOptions.value.initialDate=today
     //   console.log("calendarOptions",calendarOptions)

    };

    const getCoursesByCategory = (id) => {
      addScheduleForm.course_id = "";
      getCourse.value=true
      store
        .dispatch("longcourses/getCoursesByCategoryClassical", {
       
          "filter[category_id]": id,
        })
        .then((response) => {
       
          coursesList.value = response.data;
        });
    };
    store.dispatch("categories/CategoryList").then((response) => {
      categoriesList.value = response.data;
    });
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });
    const getChanges = () => {
      // showChange.value=true
      if (DeltedEventsList.value.length == 0) {
       //   console.log("hi", DeltedEventsList);
        Vue.swal({
          text: `You Should Add or Delete at least one course schedule`,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        table.value.fetchData(DeltedEventsList);
      }
    };

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      DeltedEventsList,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      removeAllEvents,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar();

    return {
      showChange,
      table,
      renderComponent,
      forceRerender,

      emptyChange,
      removeAllEvents,
      getChanges,
      refCalendar,
      DeltedEventsList,
      isCalendarOverlaySidebarActive,
      event,
      getCoursesByCategory,
      clearEventData,
      addEvent,
      addScheduleForm,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      coursesList,
      categoriesList,
      citiesList,
      getDate,
      getCourse,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };
  },
};
</script>

<style lang="scss">
.fc-event-time {
  display: none;
}
@import "@core/scss/vue/apps/calendar.scss";
</style>
