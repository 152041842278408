<template>
  <div
    v-if="showChange"
    class="table-list"
  >
    <!-- Table Container Card -->

    <b-card
      no-body
      class="mb-0 mt-2"
    >
      <b-table

        ref="refPriceListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="listChanges"
        striped
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Status -->
        <template #cell(start)="data">
          {{ convert(data.item.start) }}
          <!-- <span v-if="data.item.start">start</span> -->

        </template>
        <template #cell(type)="data">
          <span v-if="data.item.id">Delete</span>
          <span v-else>Add</span>
        </template>
        <template #cell(index)="data">
          {{ data.index }}
        </template>
        <template #cell(actions)="data">
          <a @click="Delete(data.index )">
            <feather-icon icon="TrashIcon" />

          </a>

        </template>

        <!-- Column: Actions -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="mt-10"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="saveChanges"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import { emit } from 'process'
import useCalendar from './useCalendar'
import store from '@/store'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,
    vSelect,
  },
  setup(props, { emit }) {
    const PricesList = ref([])
    const meta = reactive({})
    const perPageOptions = [10, 25, 50, 100, 1]
    const perPage = ref(10)
    const refPriceListTable = ref(null)
    const totalCategories = ref(0)
    const searchQuery = ref('')
    const currentPage = ref(1)
    const listChanges = ref([])
    const showChange = ref(false)

    const refetchData = () => {
      refPriceListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const fetchData = p => {
      showChange.value = true

      listChanges.value = p.value
    }

    const dataMeta = computed(() => {
      const localItemsCount = refPriceListTable.value
        ? refPriceListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    const convert = str => {
      const date = new Date(str)
      const mnth = (`0${date.getMonth() + 1}`).slice(-2)
      const day = (`0${date.getDate()}`).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    }
    // fetchData()
    const Delete = index => {
      listChanges.value.splice(index, 1)
    }
    const saveChanges = () => {
      const form = {
        ListDelete: [],
        ListAdd: [],
      }
      listChanges.value.forEach(el => {
        if (el.id) {
          form.ListDelete.push({ id: el.id })
        } else {
          el.date = convert(el.date)
          //   console.log( el)
          form.ListAdd.push({
            date: el.date,
            category_id: el.category_id,
            city_id: el.city_id,
            long_course_id: el.long_course_id,
            course_name: el.course_name,
            title: el.title,

          })
        }
      })

      const formData = new FormData()
      formData.append('ListDelete', JSON.stringify(form.ListDelete))
      formData.append('ListAdd', JSON.stringify(form.ListAdd))

      store.dispatch('longschedule/saveChanges', formData).then(response => {
        showChange.value = false
        listChanges.value = []
        emit('remove-change')

        Vue.swal({
          title: 'Changes saved ',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }).catch(error => {
        Vue.swal({
          title: '',
          text: `${error.response.data.message}`,
          icon: 'error',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const tableColumns = [
      //  'index',
      { key: 'id', label: 'ID', sortable: true },
      { key: 'title', label: 'City', sortable: true },
      { label: 'Date', key: 'start', sortable: true },
      { label: 'course', key: 'course_name', sortable: true },

      // { key: "type", label: "Action Type", sortable: true },

      { key: 'actions' },
    ]
    const { DeltedEventsList } = useCalendar()

    return {
      listChanges,
      DeltedEventsList,
      tableColumns,
      PricesList,
      saveChanges,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refPriceListTable,
      refetchData,
      fetchData,
      searchQuery,
      showChange,
      Delete,
      convert,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-list {
  width: 100%;
}
.fc-event-start.fc-event-end.fc-event-past.bg-light-undefined {
  border: 1px solid #3788d8;
  background: #3788d8;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
